import axios from 'axios'

export const intersect = (a, b, id) => {
  let set = new Set(a.map(id));
  return b.filter(el => set.has(id(el)));
}

export const isBrowser = typeof window !== `undefined`

export const sendSlackMessage = (title, message, user) => {
    const functionsUrl = process.env.LAMBDA_ADDRESS
    const slackApi = `${functionsUrl}/slack?title=${title}&message=${message}&user=${user}`;
    console.log('slackApi', slackApi);
    // console.log('functionsUrl:',`${functionsUrl}/slack?title=${encodeURIComponent(title)}&message=${encodeURIComponent(message)}&users=${users}`)
    axios.get(slackApi)
    .then(response => {
      //console.log('Slack response:',response)
    })
    .catch(error => {
      console.log(error)
    });
}

export const updateSubMenu = ( arr, key, item ) => {
  if (arr) {
    for (var i = arr.length; i--;) {
      if (arr[i].title === key) {
        let oldSub = arr[i].subnavigation;
        if (oldSub) {
          oldSub = arr[i].subnavigation.concat(item);
        }else {
          oldSub = item;
        }
        arr[i] = {title: arr[i].title, url: arr[i].url, type: arr[i].type,  subnavigation: oldSub };
      }
    }
  }
  return arr;
}

export const removeFromArray = ( arr, item ) => {
  for (var i = arr.length; i--;) {
    if (arr[i] === item) {
      arr.splice(i, 1);
    }
    //Check sub nav items
    if (arr[i] && arr[i].subnavigation) {
      for (var iv = arr[i].subnavigation.length; iv--;) {
        if (arr[i].subnavigation[iv] === item) {
          arr[i].subnavigation.splice(iv, 1);
        }
      }
    }
  }
  return arr;
}

export const slugify = (string) => {
  return string.toLowerCase().replace(' ', '-')
}

export const convertId = (title, id = 0) => {
  if (title) {
    return (
      title.toLowerCase().replace(" ", "")+id
    )
  }
}

export const ausDate = ( now, format = '/', reverse = false ) => {
  var date = new Date(now)

  var datestring = reverse ?
    date.getFullYear() + format +  `${date.getMonth()}`.padStart(2, 0) + format + `${date.getDate()}`.padStart(2, 0)
  :
    `${date.getDate()}`.padStart(2, 0)  + format + `${date.getMonth()}`.padStart(2, 0) + format + date.getFullYear()
  return datestring
}

export const getInitials = (string) => {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const stringToHslColor = (str, s = 100, l = 70) => {
  var hash = 0;

  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export const userFromId = (id, users) => {
  return users.filter(filter => filter.id === id )
}

var formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
});

export const numberToMoney = (number) => {
  return number ? formatter.format( parseInt(number) ) : 0
}

export const arraymove = (arr, fromIndex, toIndex) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

const sanitizeHtml = require('sanitize-html');

const htmlEntities = {
    nbsp: ' ',
    cent: '¢',
    pound: '£',
    yen: '¥',
    euro: '€',
    copy: '©',
    reg: '®',
    lt: '<',
    gt: '>',
    quot: '"',
    amp: '&',
    apos: '\'',
    br: '\n'
};

export const decodeEntities = (str) => {
  /* eslint no-useless-escape: 0 */
  /* eslint no-cond-assign: 0 */
  /* eslint no-bitwise: 0 */
  if (!str) return str;
  return str.toString().replace(/\&([^;]+);/g, (entity, entityCode) => {
    let match;
    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode];
    }
    if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
      return String.fromCharCode(parseInt(match[1], 16));
    }
    if (match = entityCode.match(/^#(\d+)$/)) {
        return String.fromCharCode(~~match[1]);
    }
    return entity;
  });
};

export const encodeEntities = (str) => {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/\n/g, '&br;')
}

export const stripHtmlTags = (content) => sanitizeHtml(content, { allowedTags: [], allowedAttributes: {} });

export const getExcerpt = (content, length) => {
  // Remove html tags
  const cleanedContent = stripHtmlTags(content);
  // Convert html entities to ascii, cut down content to desired length.
  const decodedTrimmedContent = decodeEntities(cleanedContent).substring(0, length);
  // If trimmed content is less than input length, add ellipsis
  const excerpt = decodedTrimmedContent.length === length ?
    `${decodedTrimmedContent.substring(0, decodedTrimmedContent.lastIndexOf(' '))}...` :
    decodedTrimmedContent;

  return excerpt;
};
