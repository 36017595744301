import React from 'react';
import Helmet from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import { addExternalCSS } from '../../utils/helpers'
import '../../sass/global/styles.scss';

const Layout = ({ children, location, wordpressUrl, includeCss = false }) => (
  <div id="layout">
    {includeCss && addExternalCSS(`${wordpressUrl}/wp-admin/admin-ajax.php?action=dynamic_css`, 'contrabass')}
    <Helmet title="Home | Vitahealth" />
    <Header location={location} />
    <div className="main">{children}</div>
    <Footer location={location} />
  </div>
)

export default Layout
