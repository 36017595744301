import React, { Component } from 'react'
import ls from 'local-storage'
import { Image } from '../Image'
import RenderContent from '../RenderContent'
import './Popup.scss'
import { getTimeDifferenceInHours } from '../../utils/helpers'


export default class Popup extends Component {
  state = {
    newsletter: ls.get('newsletter') ? ls.get('newsletter') : null,
  }

  closePopup = () => {
    ls.set('newsletter', true)
    ls.set('popupTimestamp', new Date());
    this.setState({newsletter: true})
  }

  componentDidMount () {
    this.setState((state, props) => {
      return { 
        newsletter: ls.get('popupTimestamp') ? getTimeDifferenceInHours(new Date(ls.get('popupTimestamp')), new Date()) > parseFloat(props.siteSettings.options.popupDisplayInterval) ? null : true : null
      }
    })
  }

  render() {
    const { newsletter } = this.state
    const { siteSettings } = this.props
    const { newsletterSignUp, content, headImage, enablePopup, enablePopupVideo, popupVideoLink = "" } = siteSettings.options

    if ( !enablePopup ) return null
    if ( newsletter ) return null

    return (
      <section className="newsletter-signup">
        <div className="inner">
          <button className="close" onClick={this.closePopup}/>
          {headImage && <Image src={headImage} className="head-image" position="relative"/>}
          {enablePopupVideo && 
          <div className="video-field">
            <video src={popupVideoLink} title="Popup Video" controls />
            </div>}
          <div className="content">
            {content && <RenderContent content={content}/>}
            {newsletterSignUp &&
              <form action="//vitahealth.us3.list-manage.com/subscribe/post?u=d9788459f4813ddbef63d17df&amp;id=962585a09b" method="post" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate="">
              <input type="hidden" name="b_d9788459f4813ddbef63d17df_962585a09b"/>
              <div className="form-inner">

                <div className="mc-field-group">
                  <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="Email Address"/>
                </div>

                <div className="mc-field-group">
                  <input type="text" name="FNAME" id="mce-FNAME" placeholder="First Name"/>
                </div>

                <div className="mc-field-group">
                  <input type="text" name="LNAME" id="mce-LNAME" placeholder="Last Name"/>
                </div>

                <div className="mc-field-group">
                  <select name="MMERGE5" className="" id="mce-MMERGE5">
                    <option value="">State</option>
                    <option value="WA">WA</option>
                    <option value="SA">SA</option>
                    <option value="NT">NT</option>
                  </select>
                </div>
                <div className="submit"><button type="submit">Subscribe</button></div>
              </div>
              </form>
            }
          </div>
        </div>
      </section>
    )

  }
}
