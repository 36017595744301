import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from '../../../theme/LogoFooter';
import GatsbyLink from '../GatsbyLink';
import RenderContent from '../RenderContent';
import { SocialLinks } from '../SocialLinks'
import { FaEnvelope, FaPhone, FaMapPin, FaStoreAlt } from 'react-icons/fa';
import './Footer.scss';
import '../../../theme/Footer.scss';
import { decodeEntities } from '../../utils/helpers';
import Instagram from '../Instagram'
import Popup from '../Popup'

const isClient = typeof window !== 'undefined';

class Footer extends Component {
  sendEmail(event){
    event.preventDefault()
    const { data } = this.props
    const { email } = data.siteSettings.options.locations[0]
      window.location = `mailto:${decodeEntities(email)}`;
  }

  render() {
    const { data, location = {} } = this.props;
    const {
      legalNavigation,
      footerNavigation,
      siteSettings,
      metaData,
      wordpressSettings
    } = data;
    const { url: baseUrl } = metaData;
    const { items: legalNav } = legalNavigation;
    const { items: footerNav } = footerNavigation
    const { footerTagline, navigationInFooter, contactDetailsInFooter } = siteSettings.options
    const {
      email,
      phone,
      addressDisplay
    } = siteSettings.options.locations[0];
    const currentYear = (new Date()).getFullYear();

    return (
      <>
      <Popup siteSettings={siteSettings}/>
      <Instagram account="vitahealthskincare" numberOfMediaElements={10} />
      <footer>
        <div className="footer-main">
          <div className="inner">
            <div className="wrap">

            <div className="left">
              <div className="logos">
                <img src="/logos/environ.png" className="environ"/>
                <Logo />
                {footerTagline && <RenderContent className="tagline" content={footerTagline}/>}
              </div>
              <div className="newsletter">
                <p>Stay up to date with the latest environ news</p>

                <form action="//vitahealth.us3.list-manage.com/subscribe/post?u=d9788459f4813ddbef63d17df&amp;id=962585a09b" method="post" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate="">
                <input type="hidden" name="b_d9788459f4813ddbef63d17df_962585a09b"/>
                <div className="form-inner">
                  <div><input type="email" name="EMAIL" placeholder="Your email address" required=""/></div>
                  <div className="submit"><button type="submit">Subscribe</button></div>
                </div>
                </form>
              </div>
            </div>

              {navigationInFooter &&
                <ul className="footer-nav">
                {footerNav  && footerNav.map((item, index) => (
                  <li key={index}><GatsbyLink to={item.url} aria-label={item.title} decode={true}>{item.title}</GatsbyLink>
                    {item.children &&
                      <ul>
                      {item.children && item.children.map((subItem, index) => (
                        <li key={index}><GatsbyLink to={subItem.url} aria-label={subItem.title} decode={true}>{subItem.title}</GatsbyLink></li>
                      ))}
                      </ul>
                    }
                  </li>
                ))
                }
                </ul>
              }

              {contactDetailsInFooter &&
                <div className="contact-details">
                  <p>Contact details</p>
                  {email && <div><a href="#" onClick={(event) => this.sendEmail(event)}><FaEnvelope /><span dangerouslySetInnerHTML={{__html: email}} /></a></div>}
                  {addressDisplay && <div><FaMapPin />{addressDisplay}</div>}
                  {phone && <div><a
                    href={`tel:${phone.replace(/[^A-Z0-9]/ig, "")}`}
                    className='control-call'
                    aria-label="Call Now"
                  ><FaPhone />{phone}</a></div>}
                  <div><FaStoreAlt />Mon-Thur: 9am – 5pm | Fri: 9am - 4pm</div>
                </div>
              }

            </div>
          </div>
        </div>
        <div className="disclaimers">
           <div className="wrap">
              {siteSettings.options.socialLinksFooter &&
                <SocialLinks/>
               }
               {legalNav &&
                 <div className="navigation">
                   <ul>
                     {legalNav.map((item, index) => (
                       <li key={index}><GatsbyLink to={item.url} decode={true}>{item.title}</GatsbyLink></li>
                     ))}
                   </ul>
                 </div>
               }

               <div className="copyright">
                 <span>©{currentYear} <strong>{wordpressSettings.nodes[0].title}</strong>, All rights reserved</span>
               </div>
           </div>

         </div>

      </footer>
      </>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "legal-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        },
        footerNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "footer-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        },
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            enablePopup
            headImage {
              source_url
            }
            content
            newsletterSignUp
            popupVideoLink
            enablePopupVideo
            popupDisplayInterval
          }
        },
        metaData: wordpressSiteMetadata {
          url
        }
        wordpressSettings: allWordpressWpSettings {
          nodes {
            title
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
