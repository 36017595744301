/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import fetch from "isomorphic-fetch";
import ls from "local-storage";
import LazyLoad from "react-lazy-load";
import Carousel from "../Carousel";
import { Image } from "../Image";

import "./Instagram.scss";
import { FaCircleNotch } from 'react-icons/fa'
/**
 * InstagramFeed component.
 * @param {string} title HTML content that is displayed above the component for style.
 * @returns A feed of instagram data. Data is fetched via settings from your .env.
 */
function InstagramFeed({ title = `<em>Follow</em> us on Instagram` }) {
  const [data, setData] = useState(null);
  const [instagramName, setInstagramName] = useState("distlagency");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    flag: false,
    msg: "",
  });

  useEffect(() => {
    const currentDate = new Date();
    const updateDate = new Date();
    updateDate.setHours(updateDate.getHours() + 4);
    const storeDate = data && data.time ? data.time : "";
    const compareDate = new Date(storeDate);
    if (!data || (data?.time && compareDate < currentDate)) {
      // eslint-disable-next-line no-undef
      const instagramAddress = `${process.env.GATSBY_WP_ADDRESS}/wp-json/instagram/v2/feed`;
      const fetchInstagramData = async () => {
        try {
          setIsLoading(true);
          const response = await fetch(instagramAddress);
          const responseData = await response.json();
          if (response.status === 200) {
            if (responseData?.data?.length > 0) {
              const dataFeed = { time: updateDate, feed: responseData?.data };
              // Let's make sure we are storing an iterable object into data.
              if (Object.keys(dataFeed?.feed).length > 0) {
                ls.set("instagram", dataFeed);
                setInstagramName(dataFeed?.feed[0]?.username || "");
                setData(dataFeed);
                setIsLoading(false);
              } else {
                console.log(
                  "Data returned from instagram feed: ",
                  dataFeed?.feed
                );
                throw new Error(`Non interable data format returned`);
              }
            }
          } else {
            throw new Error(
              `Non-200 status. Received: ${response.status}. ${
                response.message ? response.message : ""
              } `
            );
          }
        } catch (err) {
          console.log(err);
          setIsLoading(false);
          setError({
            flag: true,
            msg:
              err.message || "Response data does include the instagram feed.",
          });
        }
      };

      fetchInstagramData();
    }
  }, [data, instagramName]);

  const settings = {
    container: "instagram-carousel",
    nav: false,
    mouseDrag: true,
    controls: false,
    loop: false,
    gutter: 0,
    slideBy: 1,
    autoplay: true,
    rewind: true,
    speed: 2500,
    autoplayTimeout: 2500,
    responsive: {
      1600: {
        items: 6,
      },
      1024: {
        items: 4,
      },
      640: {
        items: 3,
      },
      420: {
        items: 2,
      },
      320: {
        items: 2,
      },
    },
  };
  return (
    <section className="instafeed">
      <div className="title">
        <h3 className="section-title">
          <a
            href={`https://www.instagram.com/${instagramName}/`}
            target="_blank"
            rel="noreferrer"
            dangerouslySetInnerHTML={{__html: title}}
          />
        </h3>
      </div>
      {error.flag && (
        <div className="error">
          <div className="inner">{error.msg}</div>
        </div>
      )}
      {isLoading && (
        <div className="loading">
          <FaCircleNotch/>
        </div>
      )}

      {!isLoading &&
      data &&
      typeof data !== "undefined" &&
      Object.prototype.hasOwnProperty.call(data, "feed") ? (
        <Carousel settings={settings}>
          {Object.values(data.feed).map((item) => (
            <div className="slide" key={item.id}>
              <a href={item?.permalink} target="_blank" rel="noreferrer">
                <Image
                  src={item?.thumbnail_url || item?.media_url}
                  lazy={false}
                />
              </a>
            </div>
          ))}
          <div className="slide">
            <div className="more">
              <a
                href={`https://www.instagram.com/${instagramName}/`}
                target="_blank"
                rel="noreferrer"
                className="action"
              >
                More
              </a>
            </div>
          </div>
        </Carousel>
      ) : null}
    </section>
  );
}

InstagramFeed.propTypes = {
  title: PropTypes.string,
};

InstagramFeed.defaultProps = {
  title: `<em>Follow</em> us on Instagram`,
};

export default function InstagramFeedComponent(props) {
  return (
    <LazyLoad>
      <InstagramFeed {...props} />
    </LazyLoad>
  );
}
